import React from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import '../../CSS/questionsStyles.css';
import '../../CSS/questionType2.css';
import EndButton  from './endButton';

const useSlider = (min, 
    max, 
    data, 
    setData, 
    label, 
    id,
    buttonEnabled,
    setButtonEnable
    ) => {
    const handleChange = e => {
        setData(e.target.value);
        setButtonEnable(true);
    };

    const Slider = () => (
        <div>
            <Row>
                <Col md={{ span: 5, offset: 2 }} >
                    <br />
                    <h3>
                    {data == null &&
                        0
                    }
                        {data}
                    </h3>
                    <br />
                </Col>
            </Row>
            
            <Row>
                <Col xs = {1} >{min}</Col>
                <Col xs={8}>
                    <input
                        className="inputRange"
                        type="range"
                        id={id}
                        min={min}
                        max={max}
                        step={1}
                        // value={state} // don't set value from state
                        defaultValue={data} // but instead pass state value as default value
                        //onChange={e => console.log(e.target.value)} // don't set state on all change as react will re-render
                        onMouseUp={handleChange} // only set state when handle is released
                        onTouchEnd={handleChange}
                    />
                </Col>
                <Col xs = {1} >{max}</Col>
            </Row>
        </div>
    );
    return [data, Slider, setData];
};


function QuestionType2({survey, 
    element, 
    percentComplete, 
    setPercent, 
    nxtQuestion, 
    items, 
    setItems, 
    data, 
    setData,
    buttonEnabled,
    setButtonEnable
}) {

    // Initialize elements
    // eslint-disable-next-line
    const [slideValue, Slider] = useSlider(
        0,
        element.scale,
        data,
        setData,
        "Threshold",
        "threshold",
        buttonEnabled,
        setButtonEnable
    );

    return (
        <div>
            <Container className="QuestionArea">
                <Container >
                    <Row className="TextArea">
                        <Col md={{ span: 8, offset: 2 }} >
                            <p>
                                {element.text}
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Row className="AnswerArea">
                    <Col md={{ span: 8, offset: 3 }} >
                        <Slider />
                    </Col>
                </Row>
                <Row>
                        <Col md={{ span: 8, offset: 2 }} >
                            <br />
                            <p>
                                Seleccione en la escala de 0 a {element.scale} para indicar una calificación.
                            </p>
                        </Col>
                </Row>
                <Row className="NextEndArea">
                    <Col>
                        <br />
                        <EndButton element={element} 
                                    nxtQuestion={nxtQuestion} 
                                    items={items} setItems={setItems} 
                                    data = {data} 
                                    setData = {setData}
                                    percentComplete = {percentComplete}
                                    setPercent = {setPercent}
                                    survey={survey}
                                    buttonEnabled = {buttonEnabled}
                                    setButtonEnable = { setButtonEnable }
                                    />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default QuestionType2;