import React, { useEffect } from 'react';
import InitializeSurvey from './initialializeSurvey';
import { useLocation } from "react-router-dom";

import Axios from 'axios';
/* Chages for test */
//import data from '../../encuesta.json';

export default function GetSurveyComponent({setPollName, Name, items, setItems, actualQuestioId, setactualQuestion, setPoll, poll}) {

    let query = useQuery();

    useEffect(() => {
        const addGeneralInformation = ( ) => {
            const aux = items;
            aux.CT = query.get("CT");
            aux.pollguid = query.get("pollguid");
            aux.ObjectToSave.PollID = query.get("pollid");
            aux.ObjectToSave.StudentID = query.get("studentid");
            aux.ObjectToSave.ClassScheduleID = query.get("curseid");
            aux.ObjectToSave.ProfessorID = query.get("professorid");
            setItems(aux);
        }

        addGeneralInformation();
        
        /* Changes for test */
        // const doARequest = async (pollRoute) => {    
        //     setPoll(data.Poll.questions.question);
        //     setactualQuestion(data.Poll.questions.question?.[0].ID);
        //     setPollName(data.Poll.name);
        // };

        const doARequest = async (pollRoute) => {
            const response = await Axios({
                url: window.location.protocol + "//" + window.location.host + pollRoute
              });
              setPoll(response.data.Poll.questions.question);
              setactualQuestion(response.data.Poll.questions.question?.[0].ID);
              setPollName(response.data.Poll.name);
        };

        const fetchData = async () => {
            try {
                await doARequest("/evaluacion-docente/data/"+query.get("pollguid")+".json");      
            } catch (error) {
              console.log(error);
            }
          }
          
          fetchData();
    }, [items, setItems, query, setPoll, setPollName, setactualQuestion]);

    return (
    <div >
        <InitializeSurvey
            setPollName={setPollName}
            Name={Name}
            items={items}
            setItems={setItems}
            actualQuestioId={actualQuestioId}
            setactualQuestion={setactualQuestion}
            setPoll={setPoll}
            poll={poll} />    
    </div>
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}