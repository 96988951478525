import React, { useState } from 'react';
import logo from './logo.png';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import GetSurveyComponent from './components/Pages/getSurveyComponent';
import Questions from './components/Pages/questions';


function App() {
  const [poll, setPoll] = useState();
  const [actualQuestioId, setactualQuestion] = useState("");
  const [PollName, setPollName] = useState("");


  const [items, setItems] = useState(
    {
      CT: 0,
      ObjectToSave: {
        PollID: "",
        StudentID: "",
        ClassScheduleID: "",
        ProfessorID: "",
        questions: []
      }

    }
  );

  return (
    <div className="App">
      <Container className="container z-depth-2 p-3 ">
        <Row>
          <img src={logo} className="logo" alt="logo" />
        </Row>
        <Row>
          <Col>
            <Router>
              <Switch>
                <Route exact path="/">
                  <GetSurveyComponent
                    setPollName={setPollName}
                    Name={PollName}
                    items={items}
                    setItems={setItems}
                    actualQuestioId={actualQuestioId}
                    setactualQuestion={setactualQuestion}
                    setPoll={setPoll}
                    poll={poll} />
                </Route>
                <Route path="/pregunta/:questionId">
                  <Questions survey={poll} items={items} setItems={setItems} />
                </Route>
              </Switch>
            </Router>
          </Col>
        </Row>
      </Container>
      <script src="fitty.min.js"></script>
    <script>
      fitty('.TextArea');
    </script>
    </div>
  );



}

export default App;