import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

function EndButton( {survey, 
    element, 
    nxtQuestion, 
    items, 
    setItems, 
    data, 
    setData, 
    percentComplete, 
    setPercent, 
    buttonEnabled,
    setButtonEnable
    }) 
{     

    //fetch to php file
    const fetchRequest = () => {

        const url = window.location.protocol + '//' + window.location.host + '/evaluacion-docente/';
        //const url = 'http://localhost:8080/evaluacion-docente/';
        axios.post(`${url}agregar.php`,  items )
            .then((result) => {
                window.location.href = window.location.protocol + '//' + window.location.host + result.data.redirect;
            })
            .catch((error) => {
                window.location.href = url + "r_error.php";
            });
    }
    // Function to addItems to array
    const addItem = () => {
        const newResponse = { ID: element.ID, Answer: data}
        const aux = items;
        let questionsArray = items.ObjectToSave.questions;
        if(questionsArray.length !== 0){

            if(!questionsArray.some( v => v.ID === newResponse.ID))
            {
                questionsArray.push(newResponse);
            }else{
                questionsArray = updateQuestion(questionsArray, newResponse.ID, newResponse.Answer);
            }
        } else {
            questionsArray.push(newResponse);
        }
        aux.ObjectToSave.questions = questionsArray;
        setItems(aux);
        setData();
        let newPercent = 0;
        if ((items.ObjectToSave.questions.length+1) <= survey.length) {
            newPercent = ((items.ObjectToSave.questions.length + 1) * 100)/survey.length;    
        }else{
            newPercent = 100
        }
        setPercent(Math.round(newPercent));
        setButtonEnable(false);
    }

    // Function to update an answer if already exist
    function updateQuestion( array, ID, newAnswer ) {
        for (let i in array) {
          if (array[i].ID === ID) {
            array[i].Answer = newAnswer;
             break; //Stop this loop
          }
        }
        return array;
    }

    // Function to render submit form
    function ButtonToEnd() {
        if (buttonEnabled) {
            return (
                <div>
                    <button className="specialButton button" onClick={() => { addItem(); fetchRequest() }} >ENVIAR</button>
                </div>
            )    
        }else{
            return (
                <div>
                    <button disabled={true} className="button" onClick={() => { addItem(); fetchRequest() }} >ENVIAR</button>
                </div>
            )
        }
        
    }

    // Function to render button for next question
    function ButtonToContinue( {nxtQuestion} ) {
        if (buttonEnabled) {
            return(
                <Link to={nxtQuestion}>
                    <button className="specialButton button" onClick={addItem} >CONTINUAR  &gt;</button>
                </Link>
            )
        }else{
            return(
                <Link to={nxtQuestion}>
                    <button disabled = {true} className="button" onClick={addItem} >CONTINUAR  &gt;</button>
                </Link>
            )
        }
        
    }

    if (nxtQuestion !== "END") {
        return <ButtonToContinue nxtQuestion = {nxtQuestion} /> 
    }else {
        return <ButtonToEnd />  
    }
}

export default EndButton;