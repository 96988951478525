import React from 'react';
import { 
    Container, 
    Row,
    Col
} from 'react-bootstrap';
import '../../CSS/questionsStyles.css'
import '../../CSS/questionType1.css'
import EndButton  from './endButton';

function QuestionType1({survey, 
    element, 
    percentComplete, 
    setPercent, 
    nxtQuestion , 
    items, 
    setItems, 
    data, 
    setData, 
    buttonEnabled,
    setButtonEnable
}){

    function Buttons() {
        
            let buttonYes;
            let buttonNo;
            if (data === "S") {
                buttonYes = <button class="button button3 " onClick={() => {setData("S"); setButtonEnable(true)}} style = {{ backgroundColor: "#fd7e14" }} >Si</button>
                buttonNo = <button class="button button4" onClick={() => {setData("N"); setButtonEnable(true) }} >No</button>

            }else if (data === "N"){
                buttonYes = <button class="button button3" onClick={() => {setData("S"); setButtonEnable(true) }} >Si</button>
                buttonNo = <button class="button button4 " onClick={() => {setData("N"); setButtonEnable(true) }} style={ {backgroundColor: "#e7e7e7"} } >No</button>
            }else  {
                buttonYes = <button class="button button3" onClick={() => {setData("S"); setButtonEnable(true) }} >Si</button>
                buttonNo = <button class="button button4 " onClick={() => {setData("N"); setButtonEnable(true) }} >No</button>
            }

        return (
            <div>
                <Row className="justify-content-md-center" >
                    <Col >
                        {buttonYes}
                    </Col>
                    <Col >
                        {buttonNo}
                    </Col>
                </Row>
                
            </div>
        )
    }

    return(
        <div>
            <Container className="QuestionArea">
                <Row className="TextArea">
                    <Col md={{ span: 8, offset: 2 }}>
                        <p>
                            { element.text }
                        </p>
                        <br />
                    </Col>
                </Row>
                <Buttons />
                <Row className="NextEndArea">
                    <Col>
                        <br />
                        <br />
                        <EndButton element={element} 
                                    nxtQuestion={nxtQuestion} 
                                    items={items} setItems={setItems} 
                                    data = {data} 
                                    setData = {setData}
                                    percentComplete = {percentComplete}
                                    setPercent = {setPercent}
                                    survey={survey}
                                    buttonEnabled = {buttonEnabled}
                                    setButtonEnable = { setButtonEnable }
                                    />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default QuestionType1;