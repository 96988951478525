import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../../CSS/initializeSurvey.css'
import { useLocation } from "react-router-dom";

export default function InitializeSurvey({setPollName , Name, items, setItems, actualQuestioId, setactualQuestion, setPoll, poll }) {
    let query = useQuery();

    if (poll !== undefined ) {    
        if (items.ObjectToSave.PollID === ''  
                || items.ObjectToSave.StudentID === '' 
                || items.ObjectToSave.ClassScheduleID === ''
                || items.ObjectToSave.PollID == null
                || items.ObjectToSave.StudentID == null
                || items.ObjectToSave.ClassScheduleID == null
                ) {
            return (
                <div>
                <Container className = "container z-depth-2 p-3 ">
                    <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Card
                        bg={'danger'}
                        text={'white'}
                        style={{ marginTop: '2rem' }}
                        className="mb-2"
                        >
                        <Card.Header></Card.Header>
                        <Card.Body>
                            <Card.Title>Error </Card.Title>
                            <Card.Text>
                            Ha ocurrido un error y no es posible mostrar la encuesta
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>  
                    </Row>
                </Container>
                </div>
            );
        }else{
            return (
                <div>
                    <Container className="InitializeSurveyContainer">
                        <Row>
                            <Col md={{ span: 8, offset: 2 }}>
                                <h2>{ Name }</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    A continuación se le mostrará una serie de preguntas cuyo objetivo es evaluar su experiencia como estudiante en el curso:
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5>{ query.get("nombre_curso") }</h5>        
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <div>
                                <b>Prof. { query.get("nombre_profesor") }</b>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                                <br />
                                <Link to={ "/pregunta/" + actualQuestioId }>
                                    <button className = "specialButton button" >
                                        Iniciar encuesta
                                    </button>
                                </Link>
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    La evaluación consta de 9 preguntas de tipo SI/NO, escalas de evaluación y respuesta abierta.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    } else {
        return (
          <div >
            <Container className = "container z-depth-2 p-3 ">
              <Row>
                <Col md={{ span: 8, offset: 2 }}>
                  <Card
                    bg={'danger'}
                    text={'white'}
                    style={{ marginTop: '2rem' }}
                    className="mb-2"
                  >
                    <Card.Header></Card.Header>
                    <Card.Body>
                      <Card.Title>Error </Card.Title>
                      <Card.Text>
                        Ha ocurrido un error y no es posible mostrar la encuesta
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>  
              </Row>
            </Container>
          </div>
        );
    }
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}