import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import QuestionType1 from "./questionType1";
import QuestionType2 from "./questionType2";
import QuestionType3 from "./questionType3";
import { Col, Container, Row, Card } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar'
import '../../CSS/questionsStyles.css'

function QuestionContent({ survey, questionId, poll, percentComplete, setPercent, items, setItems }) {
    const pollQuestions = poll;
    const [data, setData] = useState();
    const [buttonEnabled, setButtonEnable] = useState(false);
    const redirecToMenu = () => {
        window.location.href = window.location.protocol + '//' + window.location.host + '/evaluacion-docente/control.php';
    }

    let questionContent;
    console.log(pollQuestions)
    if (pollQuestions !== undefined) {
        for (let index = 0; index < pollQuestions.length; index++) {
            if (questionContent === undefined) {
                const element = pollQuestions[index];
                let nxtQuestion = "";
                if (element.ID === questionId) {
                    let nxtPosition = index + 1;
                    if (nxtPosition <= pollQuestions.length - 1) {
                        nxtQuestion = pollQuestions[nxtPosition].ID;
                    } else {
                        nxtQuestion = "END";
                    }
                    switch (element.type) {
                        case '1':
                            questionContent = <QuestionType1 survey={survey}
                                element={element}
                                percentComplete={percentComplete}
                                setPercent={setPercent} nxtQuestion={nxtQuestion}
                                items={items} setItems={setItems}
                                data={data}
                                setData={setData}
                                buttonEnabled={buttonEnabled}
                                setButtonEnable={setButtonEnable}
                            />;
                            break;
                        case '2':
                            questionContent = <QuestionType2 survey={survey}
                                element={element}
                                percentComplete={percentComplete}
                                setPercent={setPercent}
                                nxtQuestion={nxtQuestion}
                                items={items}
                                setItems={setItems}
                                data={data}
                                setData={setData}
                                buttonEnabled={buttonEnabled}
                                setButtonEnable={setButtonEnable}
                            />;
                            break;
                        case '3':
                            questionContent = <QuestionType3 survey={survey}
                                element={element}
                                percentComplete={percentComplete}
                                setPercent={setPercent}
                                nxtQuestion={nxtQuestion}
                                items={items}
                                setItems={setItems}
                                data={data}
                                setData={setData}
                                buttonEnabled={buttonEnabled}
                                setButtonEnable={setButtonEnable}
                            />;
                            break;
                        default:
                            questionContent = <div> <p> Existe un error y no es posible mostrar la pregunta </p> </div>;
                            break;
                    }
                }
            } else {
                break;
            }
        }
    } else {
        return (
            <div >
                <Container className="container z-depth-2 p-3 ">
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card
                                bg={'danger'}
                                text={'white'}
                                style={{ marginTop: '2rem' }}
                                className="mb-2"
                            >
                                <Card.Header></Card.Header>
                                <Card.Body>
                                    <Card.Title>Error </Card.Title>
                                    <Card.Text>
                                        Ha ocurrido un error y no es posible continuar con la encuesta.
                      </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            <button className="specialButton button" onClick={redirecToMenu}>
                                Menu Principal
                                    </button>
                        </Col>

                    </Row>
                </Container>
            </div>
        );
    }

    return (
        <div >
            { questionContent}
        </div>
    );
}

function Questions({ survey, items, setItems }) {
    const { questionId } = useParams();
    const poll = survey;
    let progressValue;
    console.log(items.ObjectToSave);
    console.log(survey);

    if (items.ObjectToSave.questions === undefined
        && items.ObjectToSave.questions === null) {
        progressValue = 0;
    } else {
        if (survey === undefined) {
            progressValue = 0;
        } else {
            progressValue = Math.round(((items.ObjectToSave.questions.length + 1) * 100) / survey.length);
        }

    }
    const [percentComplete, setPercent] = useState(progressValue);

    const progressInstance = <ProgressBar style={{ backgroundColor: '#00285f', color: '#00285f' }} now={percentComplete} label={`${percentComplete}%`} variant="warning" min={0} max={100} />;

    return (
        <div >
            <Container>
                <Row>
                    <Col md={{ span: 10, offset: 1 }} >
                        {progressInstance}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 10, offset: 1 }} >
                        <QuestionContent survey={survey} questionId={questionId} poll={poll} percentComplete={percentComplete} setPercent={setPercent} items={items} setItems={setItems} />
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Questions;