import React from 'react';
import { 
    Container, 
    Row,  
    Col
} from 'react-bootstrap';
import '../../CSS/questionsStyles.css'
import EndButton  from './endButton';



function QuestionType3( {survey, 
    element, 
    percentComplete, 
    setPercent, 
    nxtQuestion, 
    items, 
    setItems, 
    data, 
    setData, 
    buttonEnabled,
    setButtonEnable
} ){

    return(
        <div>
            <Container className="QuestionArea">
                <Row className="TextArea">
                    <Col md={{ span: 8, offset: 2 }} >
                        <p>
                            { element.text }
                        </p>
                    </Col>
                </Row>
                <Row className="AnswerArea">
                    <Col>
                        <br />
                        <textarea style = { {width: '90%' } } value={data} onChange={e => { setData(e.target.value); setButtonEnable(true) } } />
                        <br />
                    </Col>
                </Row>
                <Row className="NextEndArea">
                    <Col>
                        <br />
                        <EndButton element={element} 
                                    nxtQuestion={nxtQuestion} 
                                    items={items} 
                                    setItems={setItems} 
                                    data = {data} 
                                    setData = {setData}
                                    percentComplete = {percentComplete}
                                    setPercent = {setPercent}
                                    survey={survey}
                                    buttonEnabled = {buttonEnabled}
                                    setButtonEnable = { setButtonEnable }
                                    />
                        <br />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default QuestionType3;